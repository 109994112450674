.section_address {
  padding: 0px 20px;
  background: #EAEAEA;
}

.section_address .address {
  padding-top: 20px;
  padding-bottom: 20px;
}

.section_address .address a{
  text-decoration: none;
  color: black;
}

.section_address .address .title {
  padding-bottom: 15px;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.section_address .address .title img {
  max-width: 54px;
}

.section_address .address .title h2 {
  padding: 0px;
  margin: 0px;
  font-size: 25px;
}

.section_address .address p {
  font-weight: 500;
  font-size: 15px;
}

.section_address .address span {
  font-weight: 700;
  font-size: 17px;
  display: flex;
  gap: 10px;
}

@media screen and (max-width:767px) {
  .section_address {
    padding: 20px 0px;
  }

  .section_address .address .title img {
    max-width: 34px;
  }

  .section_address .address .title h2 {
    font-size: 18px;
  }

  .section_address .address p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .section_address .address span {
    font-size: 14px;
    flex-wrap: wrap;
  }

  .section_address .address .title {
    gap: 0.2rem;
  }

  .section_address .connect img {
    max-width: 18px;
  }
}